import Input from "../../ui/Input"
import Button from "../../ui/Button"
import { Link } from "react-router-dom"
import Checkbox from "../../ui/Checkbox"
import { useForm } from 'react-hook-form'
import Select, { components } from 'react-select'
import {breedsData, isObjEmpty, selectClassNames, genderOptions} from '../../utils/Utils'
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { signupWithJWT } from "../../store/actions/auth"
import FormLoader from "../../ui/FormLoader"
import icon_gender from "../../assets/img/icons/gender.svg"
import { getBreeds } from "../../store/actions/breeds"
import { useTranslation } from 'react-i18next'
import {useCookies} from 'react-cookie'

const SignupForm = () => {
    const [petBreed, setPetBreed] = useState()
    const [petGender, setPetGender] = useState()
    const breedsList = useSelector(state => state.breeds.breedsList)
    const isAuthLoading = useSelector(state => state.auth.isAuthLoading)
    const authError = useSelector(state => state.auth.authError)
    const { t, i18n } = useTranslation()
    const dispatch = useDispatch()
    const [cookies, setCookie] = useCookies(['theme', 'language'])

    useEffect(() => {
        dispatch(getBreeds())
    }, [dispatch])
    
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm()

    const onSubmit = data => {
        const formData = data
        formData.pet_breed = petBreed.id
        formData.pet_gender = petGender.value.toString()
        if (isObjEmpty(errors)) {
            dispatch(signupWithJWT(formData))
        }
    }

    const breedDataOptions = []
    breedsData.forEach((b) => {
        breedDataOptions.push({value: b.id, label: b.name})
    })

    const { Option } = components
    const IconOption = props => (
        <Option {...props}>
            <span className="flex justify-between">
                {props.data.label}
                <img
                     src={cookies?.theme === 'dark' ? props.data.icon : props.data.icon_dark}
                    style={{ width: 24 }}
                    alt={props.data.label}
                />
            </span>
        </Option>
    )
    const IconPlaceholder = props => (
        <span className="flex mt-[-22px]">
            <span className="mr-[10px]">{props.children}</span>
            <img
                src={icon_gender}
                style={{ width: 24 }}
                alt="Gender"
            />
        </span>
    )

    const getBreedName = (breed) => {
        const currentLanguage = i18n.language;
        return currentLanguage === 'ua' ? breed.name_ua : breed.name;
    }

    return <div className='form-wrap form-wrap__registration'>
        {isAuthLoading && <FormLoader />}
        <form className='form' onSubmit={handleSubmit(onSubmit)}>
            <h2 className='form__title h2'>{t('authorization.title_quick_registration')}</h2>
            <Input 
                name="name"
                type="text" 
                placeholder="Enter you name *Optional" 
                errors={errors}
                register={register}
                validationSchema={{ 
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    }
                }}
            />
            <Input 
                name="pet_name"
                type="text" 
                placeholder="Enter your pet name" 
                errors={errors}
                register={register}
                validationSchema={{ 
                    required: "Pet name is required",
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    }
                }}
            />
            {breedsList?.length && <Select 
                options={breedsList} 
                onChange={data => setPetBreed(data)}
                value={petBreed}
                className="w-full select-container"
                placeholder="Pet breed"
                isSearchable={false}
                classNames={selectClassNames}
                components={{
                    IndicatorSeparator: () => null
                }}
                getOptionLabel={(breedOption) => getBreedName(breedOption)}
                getOptionValue={(breedOption) => breedOption.id} 
            /> }
            <Select 
                options={genderOptions} 
                name="pet_gender"
                className="w-full select-container"
                placeholder="Choose your pet gender"
                isSearchable={false}
                classNames={selectClassNames}
                components={{
                    IndicatorSeparator: () => null,
                    Option: IconOption,
                    Placeholder: IconPlaceholder
                }}
                onChange={(data) => setPetGender(data)}
                value={petGender}
            />
            <Input 
                type="text" 
                placeholder="Enter e-mail" 
                name="email"
                errors={errors}
                register={register}
                validationSchema={{ 
                    required: "Email is required",
                    maxLength: {
                        value: 50,
                        message: "Please enter a maximum of 50 characters"
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: "Please, use correct email address"
                    }
                }}
            />
            <Input 
                type="password" 
                placeholder="Enter password" 
                name="password"
                errors={errors}
                register={register}
                validationSchema={{ 
                    required: "Password is required",
                    minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters"
                    },
                    maxLength: {
                        value: 100,
                        message: "Password cannot be more than 100 characters"
                    }
                }}
            />
            <Button tag="button" disabled={isAuthLoading}>{t('authorization.sign_up_btn')} </Button>
            <div>
                <Checkbox
                    name="email_subscription"
                    errors={errors}
                    register={register}
                >{t('authorization.receive_emails')}</Checkbox>
                <Checkbox
                    name="signup_policy"
                    errors={errors}
                    register={register}
                    validationSchema={{ 
                        required: 'Checkbox is required'
                    }}
                >{t('authorization.agree')} <Link to="/terms" className="link">{t('authorization.terms_use')}</Link> {t('authorization.and')} <Link to="/privacy" className="link">{t('authorization.privacy_policy')}</Link></Checkbox>
            </div>
            <div className='question'>
                <span className='subtitle_medium'>{t('authorization.text_have_account')} <Link className='question__link subtitle_big subtitle_big_underline' to="/login">{t('authorization.log_in_btn')}</Link></span>
            </div>
            {authError?.response?.status === 422 ? <p className='form__error'>{t('general.email_registered_message')}</p> : authError ? <p className='form__error'>{t('general.wrong_message')}</p> : ''}
        </form>
    </div>
}

export default SignupForm